
import { Component, Vue } from 'vue-property-decorator';
import 'vue-datetime/dist/vue-datetime.css';
import { getComponent } from '@/utils/helpers';
import { mixins } from 'vue-class-component';
import GenericMixin from '@/lib/mixins/GenericMixin';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),

  },
})
export default class ControlPanel extends mixins(GenericMixin) {

  created(){
   console.log( this.$store);
  }


  get items(){
    return [
      {
        linkName: 'UserAdmin',
        title: 'Users',
        description: 'View, manage, and add users.',
        icon: 'account-group'
      },
      {
        linkName: 'PersonnelScheduler',
        title: 'Personnel Scheduler',
        description: 'Configure personnel scheduler.',
        icon: 'calendar-month'
      },
      {
        linkName: 'FormBuilder',
        title: 'Form Builder',
        description: 'View, manage, and add forms.',
        icon: 'file-document-edit-outline'
      },
      {
        linkName: 'DataListBuilder',
        title: 'Data List',
        description: 'View, manage, and add datalist.',
        icon: 'dns-Outline'
      },
      {
        linkName: 'UserProfile',
        title: 'My Profile',
        description: 'Configure profile settings.',
        icon: 'account-edit'
      },
      {
        linkName: 'UserReport',
        title: 'Report',
        description: 'View Report.',
        icon: 'chart-bar'
      }
    ]
  }

}
